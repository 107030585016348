/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    p: "p",
    img: "img",
    h1: "h1"
  }, _provideComponents(), props.components), {Hero, Columns, Column, List, Video, Typography, Media, Background, Testimonial, Link, Comparison, LogoCloud, Form, Resource} = _components;
  if (!Background) _missingMdxReference("Background", true);
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Comparison) _missingMdxReference("Comparison", true);
  if (!Form) _missingMdxReference("Form", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!Link) _missingMdxReference("Link", true);
  if (!List) _missingMdxReference("List", true);
  if (!LogoCloud) _missingMdxReference("LogoCloud", true);
  if (!Media) _missingMdxReference("Media", true);
  if (!Resource) _missingMdxReference("Resource", true);
  if (!Testimonial) _missingMdxReference("Testimonial", true);
  if (!Typography) _missingMdxReference("Typography", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    background: "deepPurple",
    backgroundImage: "primary",
    color: "white01",
    headline: "The Dev-friendly Alternative to Cyclr",
    text: "Cyclr falls short when things get complicated. Prismatic provides the flexibility to handle the most complex integration scenarios."
  }), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "saas-companies-prefer-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#saas-companies-prefer-prismatic",
    "aria-label": "saas companies prefer prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SaaS companies prefer Prismatic"), React.createElement(_components.h3, {
    id: "heres-why-our-customers-choose-us-vs-cyclr",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#heres-why-our-customers-choose-us-vs-cyclr",
    "aria-label": "heres why our customers choose us vs cyclr permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Here's why our customers choose us vs Cyclr:"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Handles complex integration scenarios"), React.createElement("li", null, "Flexible dev tools that fit into your pipeline"), React.createElement("li", null, "Write code to connect to any third-party app"), React.createElement("li", null, "Start building and deploying integrations fast"))), React.createElement(Column, {
    sm: 6
  }, React.createElement(Video, {
    image: "/images/landing-page/video-thumbnail.png",
    videoId: "488710278"
  }))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2",
    spacing: "medium"
  }, React.createElement(_components.p, null, "Essential Features")), "\n", React.createElement(Columns, {
    spacing: "small"
  }, React.createElement(Media, {
    title: "Embedded Integration Marketplace",
    image: "/images/landing-page/embeddable-ux-icon.png",
    imageBorder: false
  }), React.createElement(Media, {
    title: "Pre-built and Custom Connectors",
    image: "/images/landing-page/components-icon.png",
    imageBorder: false
  }), React.createElement(Media, {
    title: "Dev-friendly Platform",
    image: "/images/landing-page/devs-love-icon.png",
    imageBorder: false
  })), "\n", React.createElement(Background, {
    background: "gray03",
    color: "gray09",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Testimonial, {
    id: "scottj"
  })), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h4",
    spacing: "large"
  }, React.createElement(_components.p, null, "Purpose-built for Native SaaS Integrations")), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "white-labeled-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#white-labeled-marketplace",
    "aria-label": "white labeled marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "White-Labeled Marketplace"), React.createElement(_components.p, null, "Give your users a seamless, fully-themed experience for activating native integrations within your product."), React.createElement(Link, {
    href: "/platform/integration-designer",
    label: "See How",
    color: "secondary"
  })), React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "integrate-with-anything",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integrate-with-anything",
    "aria-label": "integrate with anything permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integrate With Anything"), React.createElement(_components.p, null, "A library of common API connectors helps you build faster. Custom code gives you the power to build anything."), React.createElement(Link, {
    href: "/components/",
    label: "See How",
    color: "secondary"
  })), React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "devs-love-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#devs-love-prismatic",
    "aria-label": "devs love prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Devs Love Prismatic"), React.createElement(_components.p, null, "Devs and DevOps teams love the flexibility to incorporate Prismatic into their existing tool chain."), React.createElement(Link, {
    href: "/platform/built-in-a-way-devs-love",
    label: "See How",
    color: "secondary"
  }))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2"
  }, React.createElement(_components.p, null, "Scale Your Integration Strategy Fast with Prismatic")), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "pre-built-and-custom-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pre-built-and-custom-components",
    "aria-label": "pre built and custom components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pre-built and Custom Components"), React.createElement(_components.h3, {
    id: "combine-pre-built-and-custom-connectors-to-build-powerful-workflows-for-your-customers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combine-pre-built-and-custom-connectors-to-build-powerful-workflows-for-your-customers",
    "aria-label": "combine pre built and custom connectors to build powerful workflows for your customers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combine pre-built and custom connectors to build powerful workflows for your customers"), React.createElement(_components.p, null, "Handle complexity with ease. Prismatic's intuitive low-code designer empowers non-devs to create integrations by assembling connectors and other components."), React.createElement(_components.p, null, "And, devs can use our powerful SDK to code custom connectors to any third-party app your customers use.")), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/unit-test-custom-component.png",
    alt: "GraphQL API Explorer"
  })))), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/embedded-integration-marketplace.png",
    alt: "Dummy Image"
  }))), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "embedded-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#embedded-integration-marketplace",
    "aria-label": "embedded integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Embedded Integration Marketplace"), React.createElement(_components.h3, {
    id: "get-back-to-core-product-work-by-embedding-a-marketplace-with-a-few-lines-of-code",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-back-to-core-product-work-by-embedding-a-marketplace-with-a-few-lines-of-code",
    "aria-label": "get back to core product work by embedding a marketplace with a few lines of code permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get back to core product work by embedding a marketplace with a few lines of code"), React.createElement(_components.p, null, "Embed a self-serve integration marketplace quickly and get back to what matters. Plus, Prismatic provides the infrastructure and logging, monitoring, alerting tools dev and DevOps teams need to keep integrations up and running."))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2",
    spacing: "large"
  }, React.createElement(_components.p, null, "Prismatic Versus Cyclr")), "\n", React.createElement(Comparison, {
    name: "versus-cyclr"
  }), "\n", React.createElement(Background, {
    background: "gray03",
    color: "gray09",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Testimonial, {
    id: "pames2"
  })), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h3"
  }, React.createElement(_components.p, null, "Trusted by product and dev teams from startups to Fortune 100")), "\n", React.createElement(LogoCloud, {
    logos: "/images/logo-big-tin-can.svg,/images/logo-elastic.svg,/images/logo-sisu.svg,/images/logo-raven-industries.svg,/images/logo-sound-thinking.svg,/images/logo-ingenious.svg,/images/logo-hatch.svg,/images/logo-deepstream.svg",
    fullWidth: true
  }), "\n", React.createElement(Background, {
    backgroundImage: "dark",
    background: "deepPurple",
    color: "white01",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Columns, null, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h1, {
    id: "request-your-demo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#request-your-demo",
    "aria-label": "request your demo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Request Your Demo"), React.createElement(_components.p, null, "We'd love to show you why Prismatic is the leading embedded integration platform. Loved by product leaders and engineering teams alike, Prismatic helps you:"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Build integrations in hours, not months"), React.createElement("li", null, "Connect to niche vertical applications"), React.createElement("li", null, "Drive user adoption and reduce churn"), React.createElement("li", null, "Increase time for core product work")), React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Media, {
    image: "/images/landing-page/g2-badge-1.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-2.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-3.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-4.png",
    imageBorder: false
  }))), React.createElement(Column, {
    sm: 6
  }, React.createElement(Form, {
    name: "cyclr-demo",
    spacing: "medium"
  })))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h4",
    textTransform: "uppercase"
  }, React.createElement(_components.p, null, "Resources")), "\n", React.createElement(Columns, {
    spacing: "medium",
    gap: 3
  }, React.createElement(Resource, {
    backgroundImage: "card-medium-light-v1",
    description: "Learn all about embedded iPaaS: what it is, its benefits, and how to choose the best one for your team.",
    link: "/resources/embedded-ipaas-scalable-integration-strategy/",
    color: "gray12",
    overline: "Guides",
    title: "Embedded iPaaS: The Integration Strategy for B2B Software Companies"
  }), React.createElement(Resource, {
    backgroundImage: "card-large-dark-v1",
    description: "Native integrations improve the customer experience for B2B software companies. Should you build or buy?",
    link: "/resources/build-vs-buy-adding-native-integrations-to-your-product/",
    overline: "Guides",
    title: "Native Integration: A Guide to Building vs Buying Native Integrations"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
